<template>
  <section class="q-pa-md">
    <h1>Reset password</h1>
    <q-form
      autofocus
      class="q-gutter-md"
      @submit.prevent="submit"
    >
      <q-input
        v-model="innerValue.email"
        label="Email"
        type="text"
      />
      <q-btn
        color="primary"
        label="Request password reset email"
        type="submit"
      />
      <p>
        Remembered your password?
        <q-btn
          :to="`/${$route.params.locale}/sign-in`"
          label="Sign in"
        />
      </p>
      <p>
        Don't have an account?
        <q-btn
          :to="`/${$route.params.locale}/sign-up`"
          label="Sign up"
        />
      </p>
    </q-form>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const moduleName = 'auth'
  const { mapActions } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    meta: {
      title: 'Sign up',
    },
    methods: {
      ...mapActions(
        [
          'resetPassword',
        ],
      ),
      async submit () {
        try {
          await this.resetPassword(
            this.innerValue,
          )
        } catch (error) {
          return
        }

        await this.$router.push(
          `/${this.$route.params.locale}/sign-in`,
        )
      },
    },
    data () {
      return {
        innerValue: {},
      }
    },
  }
</script>
